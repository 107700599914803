<template>
	<div class="js-copy js-content pd_body">
		<div class="hk_content">
			<topHeader> </topHeader>

			<div class="dexBox_week">
				<img
					src="https://res.metaera.hk/resources/assets/images/hk/banner.png"
				/>
			</div>
			<div class="hk_atlas">
				<div
					class="hk_atlas_item"
					v-for="(mainItem, mianIndex) in list"
					:key="mianIndex"
				>
					<div class="hk_header">
						<div class="hk_header_top">
							<div class="hk_header_title">
								<img :src="`${urlPrefix}${mainItem.mainTitle}${urlSuffix}`" />
							</div>
						</div>
						<div class="hk_header_bottom">
							<div class="hk_header_title_bl1"> </div>
							<div class="hk_header_title_bl2"> </div>
						</div>
					</div>
					<div class="hk_atlas_item_list">
						<div
							class="hk_atlas_item_list_item"
							v-for="(subItem, subIndex) in mainItem.mainList"
							:key="subIndex"
						>
							<div class="hk_atlas_item_list_item_title">
								{{ subItem.subTitle }}
							</div>
							<div class="hk_atlas_item_list_content">
								<div
									class="hk_atlas_item_list_subItem"
									v-for="(item, index) in subItem.subList"
									:key="index"
								>
									<img
										:src="`${urlSubPrefix}${item.itemPic}`"
										v-if="item.itemPic && item.itemPic !== ''"
									/>
									<span>{{ item.itemName }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<div style="height: 30px"></div>
		</div>
	</div>
</template>

<script>
import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";

export default {
	name: "",
	components: {
		topHeader,
		bottom,
	},
	data() {
		return {
			active: 0,
			urlPrefix: "https://res.metaera.hk/resources/assets/images/hk/", // 你的 URL 前缀
			urlSubPrefix:
				"https://res.metaera.hk/resources/assets/images/hk/atlasPic/", // 你的 URL 前缀
			urlSuffix: ".png", // 固定的 URL 后缀

			list: [
				{
					mainTitle: "金融业务",
					mainList: [
						{
							subTitle: "DeFi",
							subList: [
								{
									itemPic: "SphereX.png",
									itemName: "SphereX",
									url: "",
								},
								{
									itemPic: "Babel Finance.png",
									itemName: "Babel Finance",
									url: "",
								},
								{
									itemPic: "BusyWhale.png",
									itemName: "BusyWhale",
									url: "",
								},
								{
									itemPic: "Orbiter Finance.png",
									itemName: "Orbiter Finance",
									url: "",
								},
							],
						},
						{
							subTitle: "虚拟资产ETF",
							subList: [
								{
									itemPic: "HashKey Capital-2.png",
									itemName: "HashKey Capital",
									url: "",
								},
								{
									itemPic: "博时基金（国际）.png",
									itemName: "博时基金（国际）",
									url: "",
								},
								{
									itemPic: "华夏基金（香港）.png",
									itemName: "华夏基金（香港）",
									url: "",
								},
								{
									itemPic: "嘉实国际.png",
									itemName: "嘉实国际",
									url: "",
								},
							],
						},
						{
							subTitle: "CeFi",
							subList: [
								{
									itemPic: "InnoMin Capital.png",
									itemName: "InnoMin Capital",
									url: "",
								},
								{
									itemPic: "MaiCapital.png",
									itemName: "MaiCapital",
									url: "",
								},
								{
									itemPic: "Skynet Trading.png",
									itemName: "Skynet Trading",
									url: "",
								},
								{
									itemPic: "Hex Trust.png",
									itemName: "Hex Trust",
									url: "",
								},
								{
									itemPic: "Babel Finance.png",
									itemName: "Babel Finance",
									url: "",
								},
								{
									itemPic: "Aquarius.png",
									itemName: "Aquarius",
									url: "",
								},
								{
									itemPic: "Avenir-2.jpg",
									itemName: "Avenir",
									url: "",
								},
								{
									itemPic: "几何资本.png",
									itemName: "几何资本",
									url: "",
								},
								{
									itemPic: "VSG.png",
									itemName: "VSG",
									url: "",
								},

								{
									itemPic: "Amber Group.png",
									itemName: "Amber Group",
									url: "",
								},
								{
									itemPic: "Metalpha.png",
									itemName: "Metalpha",
									url: "",
								},

								{
									itemPic: "Matrixport.png",
									itemName: "Matrixport",
									url: "",
								},
							],
						},
						{
							subTitle: "RWA",
							subList: [
								{
									itemPic: "MANTRA-2.jpg",
									itemName: "MANTRA",
									url: "",
								},
								{
									itemPic: "蚂蚁数科ZAN.png",
									itemName: "蚂蚁数科ZAN",
									url: "",
								},
							],
						},
						{
							subTitle: "加密支付",
							subList: [
								{
									itemPic: "HyperPay-2.png",
									itemName: "HyperPay",
									url: "",
								},
								{
									itemPic: "Alchemy.png",
									itemName: "Alchemy",
									url: "",
								},
								{
									itemPic: "恒峰国际-2.jpg",
									itemName: "恒峰国际",
									url: "",
								},
							],
						},
						{
							subTitle: "稳定币",
							subList: [
								{
									itemPic: "First Digital.png",
									itemName: "First Digital",
									url: "",
								},
								{
									itemPic: "HKT.png",
									itemName: "HKT",
									url: "",
								},
								{
									itemPic: "Animoca Brands.png",
									itemName: "Animoca Brands",
									url: "",
								},
								{
									itemPic: "渣打银行.png",
									itemName: "渣打银行",
									url: "",
								},
								{
									itemPic: "圆币.png",
									itemName: "圆币",
									url: "",
								},
								{
									itemPic: "京东币链科技Coinlink.png",
									itemName: "京东币链科技Coinlink",
									url: "",
								},
							],
						},
					],
				},
				{
					mainTitle: "持牌机构",
					mainList: [
						{
							subTitle: "券商",
							subList: [
								{
									itemPic: "华富建业证券.png",
									itemName: "华富建业证券",
									url: "",
								},
								{
									itemPic: "富强证券.png",
									itemName: "富强证券",
									url: "",
								},

								{
									itemPic: "南华证券.png",
									itemName: "南华证券",
									url: "",
								},
								{
									itemPic: "华量证券.png",
									itemName: "华量证券",
									url: "",
								},
								{
									itemPic: "长桥证券.png",
									itemName: "长桥证券",
									url: "",
								},
								{
									itemPic: "盈透证券.png",
									itemName: "盈透证券",
									url: "",
								},
								{
									itemPic: "富途证券.png",
									itemName: "富途证券",
									url: "",
								},
								{
									itemPic: "老虎证券.png",
									itemName: "老虎证券",
									url: "",
								},
								{
									itemPic: "胜利证券.png",
									itemName: "胜利证券",
									url: "",
								},
							],
						},
						{
							subTitle: "保险",
							subList: [
								{
									itemPic: "OneDegree.png",
									itemName: "OneDegree",
									url: "",
								},
							],
						},

						{
							subTitle: "香港持牌交易所",
							subList: [
								{
									itemPic: "HKVAX.png",
									itemName: "HKVAX",
									url: "",
								},
								{
									itemPic: "OSL Exchange.png",
									itemName: "OSL Exchange",
									url: "",
								},
								{
									itemPic: "HashKey Exchange.png",
									itemName: "HashKey Exchange",
									url: "",
								},
							],
						},

						{
							subTitle: "银行",
							subList: [
								{
									itemPic: "渣打银行.png",
									itemName: "渣打银行",
									url: "",
								},
								{
									itemPic: "星展银行DBS Bank.png",
									itemName: "星展银行DBS Bank",
									url: "",
								},
								{
									itemPic: "众安银行ZA Bank.png",
									itemName: "众安银行ZA Bank",
									url: "",
								},
								{
									itemPic: "HSBC Bank.png",
									itemName: "HSBC Bank",
									url: "",
								},
							],
						},
					],
				},
				{
					mainTitle: "Venture capital",
					mainList: [
						{
							subTitle: "Venture capital",
							subList: [
								{
									itemPic: "Chiron.png",
									itemName: "Chiron",
									url: "",
								},
								{
									itemPic: "Double Peak.jpg",
									itemName: "Double Peak",
									url: "",
								},
								{
									itemPic: "HashKey Capital.png",
									itemName: "HashKey Capital",
									url: "",
								},
								{
									itemPic: "MindWorks Capital .png",
									itemName: "MindWorks Capital ",
									url: "",
								},
								{
									itemPic: "Newman Capital.png",
									itemName: "Newman Capital",
									url: "",
								},
								{
									itemPic: "GBV Capital.png",
									itemName: "GBV Capital",
									url: "",
								},
								{
									itemPic: "Token Bay Capital.png",
									itemName: "Token Bay Capital",
									url: "",
								},
								{
									itemPic: "EVG.png",
									itemName: "EVG",
									url: "",
								},
								{
									itemPic: "C Capital.png",
									itemName: "C Capital",
									url: "",
								},
								{
									itemPic: "GBV Capitalpng",
									itemName: "GBV Capital",
									url: "",
								},
								{
									itemPic: "Kenetic Capital.png",
									itemName: "Kenetic Capital",
									url: "",
								},
								{
									itemPic: "LD Capital.png",
									itemName: "LD Capital",
									url: "",
								},
								{
									itemPic: "Matrixport Ventures.png",
									itemName: "Matrixport Ventures",
									url: "",
								},
								{
									itemPic: "Aquarius.png",
									itemName: "Aquarius",
									url: "",
								},
								{
									itemPic: "LD Capital.png",
									itemName: "LD Capital",
									url: "",
								},
								{
									itemPic: "Cipholio.png",
									itemName: "Cipholio",
									url: "",
								},
								{
									itemPic: "Aquarius.png",
									itemName: "Aquarius",
									url: "",
								},
								{
									itemPic: "Cipholio.png",
									itemName: "Cipholio",
									url: "",
								},
								{
									itemPic: "SatoshiLab.png",
									itemName: "SatoshiLab",
									url: "",
								},
								{
									itemPic: "Waterdrip Capital.png",
									itemName: "Waterdrip Capital",
									url: "",
								},
								{
									itemPic: "CGV.png",
									itemName: "CGV",
									url: "",
								},
								{
									itemPic: "CyberPort.png",
									itemName: "CyberPort",
									url: "",
								},
								{
									itemPic: "CTH Group.png",
									itemName: "CTH Group",
									url: "",
								},
								{
									itemPic: "Animoca Brands.png",
									itemName: "Animoca Brands",
									url: "",
								},
							],
						},
					],
				},
				{
					mainTitle: "Web3.0生态应用",
					mainList: [
						{
							subTitle: "Web3.0生态服务",
							subList: [
								{
									itemPic: "RootData.png",
									itemName: "RootData",
									url: "",
								},
								{
									itemPic: "Draper Dragon Web 3.0.png",
									itemName: "Draper Dragon Web 3.0",
									url: "",
								},
								{
									itemPic: "D11 Labs.png",
									itemName: "D11 Labs",
									url: "",
								},
								{
									itemPic: "Web3 Labs_1.jpg",
									itemName: "Web3 Labs",
									url: "",
								},
								{
									itemPic: "科技园.png",
									itemName: "科技园",
									url: "",
								},
								{
									itemPic: " 数码港.png",
									itemName: " 数码港",
									url: "",
								},
							],
						},

						{
							subTitle: "Miner服务商",
							subList: [
								{
									itemPic: "JDK Capital.png",
									itemName: "JDK Capital",
									url: "",
								},
								{
									itemPic: "BIT Mining.png",
									itemName: "BIT Mining",
									url: "",
								},
								{
									itemPic: "iPollo_1.jpg",
									itemName: "iPollo",
									url: "",
								},
								{
									itemPic: "比特大陆.png",
									itemName: "比特大陆",
									url: "",
								},
							],
						},
						{
							subTitle: "Web3.0基础服务",
							subList: [
								{
									itemPic: "Manta Network-2.png",
									itemName: "Manta Network",
									url: "",
								},
								{
									itemPic: "Titan Network-2.png",
									itemName: "Titan Network",
									url: "",
								},
								{
									itemPic: "BiHelix-2.png",
									itemName: "BiHelix",
									url: "",
								},
							],
						},
						{
							subTitle: "Web3赛道相关上市公司",
							subList: [
								{
									itemPic: "博雅互动.png",
									itemName: "博雅互动",
									url: "",
								},
								{
									itemPic: "美图.png",
									itemName: "美图",
									url: "",
								},
								{
									itemPic: "蓝港互动.png",
									itemName: "蓝港互动",
									url: "",
								},
								{
									itemPic: "新火科技.png",
									itemName: "新火科技",
									url: "",
								},
								{
									itemPic: "OSL.png",
									itemName: "OSL",
									url: "",
								},
							],
						},
						{
							subTitle: "社交",
							subList: [
								{
									itemPic: "Only1.png",
									itemName: "Only1",
									url: "",
								},
								{
									itemPic: "CrossSpace.png",
									itemName: "CrossSpace",
									url: "",
								},
								{
									itemPic: "Mask Network.png",
									itemName: "Mask Network",
									url: "",
								},
							],
						},
						{
							subTitle: "游戏及NFT",
							subList: [
								{
									itemPic: "Nobody.jpg",
									itemName: "Nobody",
									url: "",
								},
								{
									itemPic: "Ultiverse.png",
									itemName: "Ultiverse",
									url: "",
								},
								{
									itemPic: "Ucollex.png",
									itemName: "Ucollex",
									url: "",
								},
								{
									itemPic: "BNV.png",
									itemName: "BNV",
									url: "",
								},
								{
									itemPic: "Catheon Gaming.png",
									itemName: "Catheon Gaming",
									url: "",
								},
								{
									itemPic: "MOBOX.png",
									itemName: "MOBOX",
									url: "",
								},
								{
									itemPic: "Element.png",
									itemName: "Element",
									url: "",
								},
								{
									itemPic: "Memeland.png",
									itemName: "Memeland",
									url: "",
								},
								{
									itemPic: "The Sandbox.png",
									itemName: "The Sandbox",
									url: "",
								},
							],
						},
						{
							subTitle: "钱包",
							subList: [
								{
									itemPic: "MPCVault.png",
									itemName: "MPCVault",
									url: "",
								},
								{
									itemPic: "Stable Wallet.png",
									itemName: "Stable Wallet",
									url: "",
								},
								{
									itemPic: "Token Pocket.png",
									itemName: "Token Pocket",
									url: "",
								},
							],
						},
						{
							subTitle: "安全",
							subList: [
								{
									itemPic: "Beosin.png",
									itemName: "Beosin",
									url: "",
								},
								{
									itemPic: "慢雾.png",
									itemName: "慢雾",
									url: "",
								},
								{
									itemPic: "Certik.png",
									itemName: "Certik",
									url: "",
								},
							],
						},
						{
							subTitle: "公链",
							subList: [
								{
									itemPic: "MANTRA-2.jpg",
									itemName: "MANTRA",
									url: "",
								},
								{
									itemPic: "QTUM.png",
									itemName: "QTUM",
									url: "",
								},
								{
									itemPic: "Elastos.png",
									itemName: "Elastos",
									url: "",
								},
								{
									itemPic: "PlatON.png",
									itemName: "PlatON",
									url: "",
								},
								{
									itemPic: "Manta Network.png",
									itemName: "Manta Network",
									url: "",
								},
								{
									itemPic: "HashKey Chain.png",
									itemName: "HashKey Chain",
									url: "",
								},
								{
									itemPic: "OKT Chain.png",
									itemName: "OKT Chain",
									url: "",
								},
								{
									itemPic: "Conflux.png",
									itemName: "Conflux",
									url: "",
								},
							],
						},
					],
				},
			],
		};
	},
	created() {},
	methods: {},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
	background: #000000;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	/* background: black; */
}

.hk_content {
	position: relative;
	z-index: 2;
	min-height: 100vh;
	box-sizing: border-box;
	background-position: center calc(120%); /* 背景图片居中且向下偏移100px */
	background-repeat: no-repeat; /* 不重复背景图片 */
	background-size: contain; /* 调整图片大小以适应容器 */
}

.dexBox_week img {
	margin-top: 20px;
	width: 80%;
	margin-left: 10%;
	object-fit: cover;
	border-radius: 20px;
}
.dexBox_week {
	width: 100%;
	margin-top: 100px;
}
@media screen and (max-width: 900px) {
	.dexBox_week {
		margin-top: 60px;
	}
	.dexBox_week img {
		margin-top: 20px;
		width: 90%;
		margin-left: 5%;
		border-radius: 20px;
	}
}
</style>
<style scoped>
.hk_atlas {
	margin-left: 10%;
	width: 80%;
	padding-top: 10px;
	background: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 900px) {
	.Main {
		display: contents;
	}
}
</style>

<!-- hk_header -->
<style scoped>
.hk_header {
	margin-top: clamp(16px, calc(100vw * 20 / 1280), 30px);
}

.hk_header_top {
	display: flex;
	align-items: center;
}

.hk_header_bottom {
	margin-top: 10px;
}
</style>

<!-- hk_atlas  -->
<style scoped>
.hk_atlas_item_list_item {
	margin-top: 20px;

	border-radius: 10px;
	padding: 20px;
	opacity: 1;
	background: #121212;
	box-sizing: border-box;
	border: 4px solid #0e0e0e;
	box-shadow: inset 0px 0px 23px 0px rgba(0, 0, 0, 0.6);
}

.hk_atlas_item_list_content {
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 10px;
}

.hk_atlas_item_list_item_title {
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0em;
	color: #ffffff;
}

.hk_atlas_item_list_subItem {
	margin-right: 20px;
	margin-top: 20px;
	align-items: center;
	align-content: center;
	display: flex;
}
.hk_atlas_item_list_subItem img {
	width: 20px;
	height: 20px;
	object-fit: cover;
	margin-right: 6px;
	border-radius: 2px;
	opacity: 1;
}
.hk_atlas_item_list_subItem span {
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
</style>
